import { createContext, useContext, useReducer } from "react";
import { AuthReducer, initialState } from "./reducer";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

/// <summary>
/// Author: KurisuCodes
/// </summary>
export function useAuthState() {
    const context = useContext(AuthStateContext);

    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider');
    }

    return context;
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export function useAuthDispatch() {
    const context = useContext(AuthDispatchContext);

    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider');
    }

    return context;
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
}