import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import TransferSendStep1 from 'assets/img/transfer-send-step1.png';
import TransferSendStep2 from 'assets/img/transfer-send-step2.png';
import TransferSendStep3 from 'assets/img/transfer-send-step3.png';
import TransferSendStep4 from 'assets/img/transfer-send-step4.png';
import TransferSendStep5 from 'assets/img/transfer-send-step5.png';
import TransferSendStep6 from 'assets/img/transfer-send-step6.png';
import TransferSendStep7 from 'assets/img/transfer-send-step7.png';

import TransferUsingNumber from 'assets/img/transfer-send-phone.png';
import TransferUsingAlbum from 'assets/img/transfer-send-album.png';

const SendCredit = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('SEND_ECREDIT_STEP_1'),
            img: TransferSendStep1
        },
        {
            text: t('SEND_ECREDIT_STEP_2'),
            img: TransferSendStep2
        },
        {
            badge: t('SCAN_QR_CODE'),
            text: t('SEND_ECREDIT_STEP_3'),
            img: TransferSendStep3
        },
        {
            badge: t('PHONE_NUMBER'),
            text: t('SEND_ECREDIT_STEP_3_PHONE'),
            img: TransferUsingNumber
        },
        {
            badge: t('QR_CODE_IMAGE'),
            text: t('SEND_ECREDIT_STEP_3_ALBUM'),
            img: TransferUsingAlbum
        },
        {
            text: t('SEND_ECREDIT_STEP_4'),
            img: TransferSendStep4
        },
        {
            text: t('SEND_ECREDIT_STEP_5'),
            img: TransferSendStep5
        },
        {
            text: t('SEND_ECREDIT_STEP_6'),
            img: TransferSendStep6
        },
        {
            text: t('SEND_ECREDIT_STEP_7'),
            img: TransferSendStep7
        }
    ]

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default SendCredit;