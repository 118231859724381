import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ECredit from './ECredit';
import EPoint from './EPoint';
import Reward from './Reward';

const WithdrawIndex = () => {
    const { t } = useTranslation();

    return (
        <Tabs defaultActiveKey="ecredit" justify className="brand-nav-tabs">
            <Tab eventKey="ecredit" title="e-Credit">
                <ECredit />
            </Tab>
            <Tab eventKey="epoint" title="e-Point">
                <EPoint />
            </Tab>
        </Tabs>
    );
};

export default WithdrawIndex;