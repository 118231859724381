import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OnlineBanking from './OnlineBanking';
import QrCode from './QrCode';

const DepositIndex = () => {
    const { t } = useTranslation();

    return (
        <Tabs defaultActiveKey="online-banking" justify className="brand-nav-tabs">
             <Tab eventKey="online-banking" title={t("ONLINE_BANKING")}>
                <OnlineBanking />
            </Tab>
            <Tab eventKey="homes" title={t("QR_CODE")}>
                <QrCode />
            </Tab>
        </Tabs>
    );
};

export default DepositIndex;