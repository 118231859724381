import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import ReceiveCreditStep1 from 'assets/img/transfer-receive-step1.png';
import ReceiveCreditStep2 from 'assets/img/transfer-receive-step2.png';
import ReceiveCreditStep2_Display from 'assets/img/transfer-receive-step2-option1.png';
import ReceiveCreditStep2_Share from 'assets/img/transfer-receive-step2-option2.png';

const ReceiveCredit = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('RECEIVE_ECREDIT_STEP_1'),
            img: ReceiveCreditStep1
        },
        {
            text: t('RECEIVE_ECREDIT_STEP_2'),
            img: ReceiveCreditStep2
        },
        {
            text: t('RECEIVE_ECREDIT_STEP_3_DISPLAY'),
            img: ReceiveCreditStep2_Display
        },
        {
            text: t('RECEIVE_ECREDIT_STEP_3_SHARE'),
            img: ReceiveCreditStep2_Share
        }
    ]

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default ReceiveCredit;