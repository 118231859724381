export default {
    LOGIN: '/login',
    HOME: '/',
    RULES: '/rules-regulations',
    
    TRANSACTION_INDEX: '/transaction',
    DEPOSIT_INDEX: '/transaction/deposit',
    WITHDRAW_INDEX: '/transaction/withdraw',
    TRANSFER_INDEX: '/transaction/transfer',

    CONVERT_INDEX: '/convert',
    CONVERT_REWARD: '/convert/reward',
    CONVERT_CONVERTER: '/convert/converter',
    CONVERT_TRANSFER: '/convert/transfer',

    RANKING_INDEX: '/ranking',
    RANKING_GAIN_EXP: '/ranking/gain-exp',
    RANKING_DROP_EXP: '/ranking/drop-exp',
    RANKING_DROP_RANK: '/ranking/drop-rank',

    PRIVACY_POLICY: '/privacy-policy'
}